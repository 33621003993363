var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "follow-wrapper bj" }, [
    _c("div", { staticClass: "title" }, [
      _c(
        "div",
        { staticClass: "seach order-wrapper" },
        [
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("创建日期：")]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  align: "right",
                  "picker-options": _vm.pickerOptions,
                },
                on: { change: _vm.changeCreateDate },
                model: {
                  value: _vm.createDate,
                  callback: function ($$v) {
                    _vm.createDate = $$v
                  },
                  expression: "createDate",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("完成日期：")]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  align: "right",
                  "picker-options": _vm.pickerOptions,
                },
                on: { change: _vm.changeFinishDate },
                model: {
                  value: _vm.finishDate,
                  callback: function ($$v) {
                    _vm.finishDate = $$v
                  },
                  expression: "finishDate",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("支付日期：")]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  align: "right",
                  "picker-options": _vm.pickerOptions,
                },
                on: { change: _vm.changePayDate },
                model: {
                  value: _vm.payDate,
                  callback: function ($$v) {
                    _vm.payDate = $$v
                  },
                  expression: "payDate",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("供货日期：")]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  align: "right",
                  "picker-options": _vm.pickerOptions,
                },
                on: { change: _vm.changeSupplierDate },
                model: {
                  value: _vm.supplierDate,
                  callback: function ($$v) {
                    _vm.supplierDate = $$v
                  },
                  expression: "supplierDate",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                staticClass: "jn-input-style",
                attrs: { placeholder: "请输入订单号", clearable: "" },
                model: {
                  value: _vm.formData.order_no,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "order_no", $$v)
                  },
                  expression: "formData.order_no",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                staticClass: "jn-input-style",
                attrs: { placeholder: "请输入售后单号", clearable: "" },
                model: {
                  value: _vm.formData.as_no,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "as_no", $$v)
                  },
                  expression: "formData.as_no",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                staticClass: "jn-input-style",
                attrs: { placeholder: "请输入客户电话", clearable: "" },
                model: {
                  value: _vm.formData.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "mobile", $$v)
                  },
                  expression: "formData.mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                staticClass: "jn-input-style",
                attrs: { placeholder: "请输入商品名称", clearable: "" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                staticClass: "jn-input-style",
                attrs: { placeholder: "请输入货号", clearable: "" },
                model: {
                  value: _vm.formData.sku_code,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "sku_code", $$v)
                  },
                  expression: "formData.sku_code",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  staticClass: "jn-input-style",
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择二级分类",
                  },
                  model: {
                    value: _vm.formData.cid,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "cid", $$v)
                    },
                    expression: "formData.cid",
                  },
                },
                _vm._l(_vm.subCidList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  staticClass: "jn-input-style",
                  attrs: { clearable: "", placeholder: "请选择售后原因" },
                  model: {
                    value: _vm.formData.reason_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "reason_type", $$v)
                    },
                    expression: "formData.reason_type",
                  },
                },
                _vm._l(_vm.reasonList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  staticClass: "jn-input-style",
                  attrs: { clearable: "", placeholder: "请选择集配中心" },
                  model: {
                    value: _vm.formData.logistic_business_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "logistic_business_id", $$v)
                    },
                    expression: "formData.logistic_business_id",
                  },
                },
                _vm._l(_vm.businessList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  staticClass: "jn-input-style",
                  attrs: { clearable: "", placeholder: "请选择审批状态" },
                  model: {
                    value: _vm.formData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status",
                  },
                },
                _vm._l(_vm.auditStatusList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  staticClass: "jn-input-style",
                  attrs: {
                    placeholder: "选择城市仓",
                    "filter-method": (val) =>
                      _vm.onFilterMethod("cityStore", val),
                    filterable: "",
                    clearable: "",
                  },
                  on: {
                    change: _vm.onChangeCityStore,
                    "visible-change": (val) =>
                      _vm.onChangeVisible("cityStore", val),
                  },
                  model: {
                    value: _vm.formData.logistics_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "logistics_id", $$v)
                    },
                    expression: "formData.logistics_id",
                  },
                },
                _vm._l(_vm.cityStoreList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  staticClass: "jn-input-style",
                  attrs: {
                    placeholder: "选择自提点",
                    "filter-method": (val) =>
                      _vm.onFilterMethod("selfTake", val),
                    filterable: "",
                    clearable: "",
                  },
                  on: {
                    "visible-change": (val) =>
                      _vm.onChangeVisible("selfTake", val),
                  },
                  model: {
                    value: _vm.formData.delivery_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "delivery_id", $$v)
                    },
                    expression: "formData.delivery_id",
                  },
                },
                _vm._l(_vm.selfTakeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                staticClass: "jn-input-style",
                attrs: { placeholder: "请输入采购员姓名", clearable: "" },
                model: {
                  value: _vm.formData.buyer_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "buyer_name", $$v)
                  },
                  expression: "formData.buyer_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                staticClass: "jn-input-style",
                attrs: { placeholder: "请输入供应商名称", clearable: "" },
                model: {
                  value: _vm.formData.supplier_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "supplier_name", $$v)
                  },
                  expression: "formData.supplier_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  staticClass: "jn-input-style",
                  attrs: { clearable: "", placeholder: "是否城市仓提报" },
                  model: {
                    value: _vm.formData.is_tdc_submit,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "is_tdc_submit", $$v)
                    },
                    expression: "formData.is_tdc_submit",
                  },
                },
                _vm._l(_vm.isTdcSubmitList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value + "" },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                staticClass: "jn-input-style",
                attrs: { placeholder: "请输入供货单号", clearable: "" },
                model: {
                  value: _vm.formData.supplier_order_no,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "supplier_order_no", $$v)
                  },
                  expression: "formData.supplier_order_no",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  staticClass: "jn-input-style",
                  attrs: { clearable: "", placeholder: "请选择用户类型" },
                  model: {
                    value: _vm.formData.membership_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "membership_status", $$v)
                    },
                    expression: "formData.membership_status",
                  },
                },
                _vm._l(_vm.customerTypeList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value + "" },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  staticClass: "jn-input-style",
                  attrs: { clearable: "", placeholder: "是否仓主专区商品" },
                  model: {
                    value: _vm.formData.is_tdc_owner,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "is_tdc_owner", $$v)
                    },
                    expression: "formData.is_tdc_owner",
                  },
                },
                _vm._l(_vm.warehouseList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value + "" },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-autocomplete", {
                staticClass: "jn-input-style",
                attrs: {
                  "fetch-suggestions": _vm.querySearchAsync,
                  placeholder: "请输入组名",
                },
                model: {
                  value: _vm.formData.team_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "team_name", $$v)
                  },
                  expression: "formData.team_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.onHandleSearch },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: !_vm.tableData.length || _vm.disabledExport,
                type: "warning",
                icon: "el-icon-download",
              },
              on: { click: _vm.onHandleExport },
            },
            [_vm._v("导出")]
          ),
          _c("el-button", {
            staticClass: "bjsearch",
            staticStyle: { "margin-left": "5px" },
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.onHandleRefresh },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "auto-table-flex" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              data: _vm.tableData,
              height: "100%",
              border: true,
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "logistic_business_name",
                "min-width": "150",
                "show-overflow-tooltip": "",
                align: "center",
                label: "集配中心",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.logistic_business_name || "-")),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "supplier_name",
                "min-width": "150",
                "show-overflow-tooltip": "",
                align: "center",
                label: "供货商",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.getSupplierName(scope.row.aftersale))
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "buyer_name", align: "center", label: "采购员" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.getSupplierBuyerName(scope.row.aftersale))
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "logistics_name",
                align: "center",
                label: "城市仓",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.getLogisticsName(scope.row.aftersale))
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "delivery_name",
                width: "150",
                "show-overflow-tooltip": "",
                align: "center",
                label: "自提点",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.getDeliveryName(scope.row.aftersale))
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "order_no",
                width: "180",
                align: "center",
                label: "订单号",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (scope.row.aftersale &&
                              scope.row.aftersale.order_no) ||
                              "-"
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "clientele_services_team_name",
                width: "180",
                align: "center",
                label: "组名",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "member_name",
                align: "center",
                label: "客户名称",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getMemberName(scope.row.aftersale)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "membership_status",
                align: "center",
                label: "客户类型",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.customerTypeList.find(
                              (item) =>
                                item.value ===
                                Number(
                                  scope.row?.aftersale?.order?.membership_status
                                )
                            )?.label || "-"
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "member_mobile",
                width: "150",
                align: "center",
                label: "客户电话",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getMemberMobile(scope.row.aftersale)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "as_no",
                width: "180",
                align: "center",
                label: "售后单号",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (scope.row.aftersale &&
                              scope.row.aftersale.as_no) ||
                              "-"
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "create_time",
                align: "center",
                width: "150",
                label: "创建时间",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(" " + _vm._s(scope.row.create_time || "-") + " "),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "supplier_create_at",
                align: "center",
                width: "150",
                label: "供货日期",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "goods_name",
                align: "center",
                width: "150",
                label: "商品名称",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getGoodsName(scope.row.aftersale)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "sku_code",
                align: "center",
                width: "150",
                label: "货号",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "is_tdc_owner_txt",
                align: "center",
                width: "150",
                label: "是否仓主专区",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "cate_name",
                align: "center",
                width: "150",
                label: "二级分类",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "reason_name",
                align: "center",
                width: "150",
                label: "售后原因",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.getReason(scope.row.aftersale))),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "is_tdc_submit",
                width: "180",
                align: "center",
                label: "城市仓提报",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.isTdcSubmitList.find(
                              (item) =>
                                item.value ===
                                scope.row?.aftersale?.is_tdc_submit
                            )?.label || "-"
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "status_name",
                width: "150",
                align: "center",
                label: "审批状态",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status === _vm.AUDIT_STATUS_ENUM.ing.value
                        ? _c("el-tag", { attrs: { type: "warning" } }, [
                            _vm._v(_vm._s(_vm.AUDIT_STATUS_ENUM.ing.label)),
                          ])
                        : _vm._e(),
                      scope.row.status === _vm.AUDIT_STATUS_ENUM.return.value
                        ? _c("el-tag", { attrs: { type: "success" } }, [
                            _vm._v(_vm._s(_vm.AUDIT_STATUS_ENUM.return.label)),
                          ])
                        : _vm._e(),
                      scope.row.status === _vm.AUDIT_STATUS_ENUM.reject.value
                        ? _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v(_vm._s(_vm.AUDIT_STATUS_ENUM.reject.label)),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "操作",
                "min-width": "150",
                align: "center",
                fixed: "right",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status === _vm.AUDIT_STATUS_ENUM.ing.value
                        ? _c(
                            "router-link",
                            {
                              staticClass: "main-color pointer text-none",
                              attrs: {
                                to: {
                                  name: "newAfterCheck",
                                  query: {
                                    id: scope.row.as_id,
                                    state: scope.row.state,
                                  },
                                },
                              },
                            },
                            [_vm._v("去审批 ")]
                          )
                        : _c(
                            "router-link",
                            {
                              staticClass: "main-color pointer text-none",
                              attrs: {
                                to: {
                                  name: "newAfterDetail",
                                  query: {
                                    id: scope.row.as_id,
                                    state: scope.row.state,
                                  },
                                },
                              },
                            },
                            [_vm._v("查看详情 ")]
                          ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            background: "",
            "current-page": _vm.formData.page,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.formData.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.onHandleSizeChange,
            "current-change": _vm.onHandleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }